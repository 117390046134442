// @ts-nocheck
import { CSSProperties } from "react";
import { ITransitionData } from "./transitions";

export const rotate = (x: string | number): string => `rotate(${x}deg)`;

export const rotate3d = (x: string | number, y: string | number, z: string | number, deg: number): string => `rotate3d(${x}, ${y}, ${z}, ${deg}deg)`

export const scale = (x: number, y?: number): string => `scale(${x}, ${y || x})`;

export const translate = (x: string | number, y: number | string): string => `translate(${x}px, ${y}px)`;

export const multiple = (...cssFunctions: Array<string>): string => cssFunctions.join(" ");

export type ISceneAnimationKeyframe = Record<number, CSSProperties>

export const rotateAbout = (start: number, duration: number = 500, speed: number): ISceneAnimationKeyframe => {
  const increment = duration / 3;

  return {
    [start]: {
      transform: rotate(0),
    },
    [start + increment]: {
      transform: rotate(speed),
    },
    [start + 2 * increment]: {
      transform: rotate(2 * speed),
    },
    [start + 3 * increment]: {
      transform: rotate(3 * speed),
    },
  }
}

export const smokeMachine = (start: number, repetitions: number): ISceneAnimationKeyframe => {
  const transitions: ISceneAnimationKeyframe = {};
  const duration = 400;

  for (let r = 0; r < repetitions; r++) {
    transitions[start + duration * r] = {
      opacity: 0,
      transform: multiple(
        translate(0, parseInt((0 - Math.random() * 100 - 100).toString(), 10)),
        scale(1, 1)
      ),
    };

    transitions[start + duration * r + 1] = {
      opacity: 0.3,
      transform: multiple(translate(0, 0), scale(1, 1)),
    };
  }

  return transitions;
};

export const appearAt = (start: number, duration: number = 1, finalOpacity: number = 1): ISceneAnimationKeyframe => ({
  0: {
    opacity: 0,
  },
  [start]: {
    opacity: 0,
  },
  [start + duration]: {
    opacity: finalOpacity,
  },
});

export const disappearAt = (start: number, duration: number = 1, startingOpacity: number = 1): ISceneAnimationKeyframe => ({
  [start]: {
    opacity: startingOpacity,
  },
  [start + duration]: {
    opacity: 0,
  },
});

export const display = (start: number, durationStart: number, end: number, durationEnd: number, finalOpacity: number = 1): ISceneAnimationKeyframe => ({
  ...appearAt(start, durationStart, finalOpacity),
  ...disappearAt(end, durationEnd, finalOpacity),
});

export const explodeIt = (
  offsetX: number = -500,
  offsetY: number = 200,
  scaleXY: number = 2,
  rotateDeg: number = 0,
  start: number
): ISceneAnimationKeyframe => {
  const idle = {
    transform: multiple(translate(0, 0), scale(1), rotate(0)),
  };
  const idle2 = {
    transform: multiple(translate(0, 0), scale(0.8), rotate(0)),
  };

  return {
    0: idle,
    [start - 70]: idle,
    [start - 20]: {
      transform: multiple(
        translate(offsetX, offsetY),
        scale(scaleXY),
        rotate(rotateDeg)
      ),
    },
    [start]: idle2,
    [start + 20]: idle,
  };
};

export const drawStrokes = (start: number = 0, strokeDasharray: number = 1280, speed: number = 1): ISceneAnimationKeyframe => {
  const keyframe = 100 * speed;

  return {
    0: {
      opacity: 0,
    },
    [0 + start - 1]: {
      opacity: 0,
    },
    [0 + start]: {
      opacity: 1,
      strokeDashoffset: strokeDasharray,
      strokeDasharray,
      strokeOpacity: 1,
      fillOpacity: 0,
    },
    [keyframe / 2 + start]: {
      opacity: 1,
      strokeDashoffset: 0,
      strokeDasharray,
      fillOpacity: 0,
      strokeOpacity: 1,
    },
    [keyframe + start]: {
      opacity: 1,
      strokeDashoffset: 0,
      strokeDasharray,
      fillOpacity: 1,
      strokeOpacity: 0,
    },
  };
};

export const drawStrokesAndHide = (start: number, strokeDasharray: number, speed: number, end: number = 80): ISceneAnimationKeyframe => {
  const keyframe = 50 * speed;
  return {
    ...drawStrokes(start, strokeDasharray, speed),
    ...{
      [start + end - 20]: {
        opacity: 1,
        strokeDashoffset: 0,
        strokeDasharray,
        fillOpacity: 1,
        strokeOpacity: 0,
      },
      [start + keyframe + end]: {
        opacity: 0,
        strokeDashoffset: 0,
        strokeDasharray,
        fillOpacity: 0,
        strokeOpacity: 0,
      },
    },
  };
};

export const animateSourceCodes = (start: number, end: number): ISceneAnimationKeyframe => {
  const timeActive = end - start;

  const times = parseInt((timeActive / 50).toString(), 10);
  const output: ISceneAnimationKeyframe = {};

  for (let index = 0; index < times; index++) {
    output[start + 50 * index] = {
      transform: translate(0, `${(Math.random() * 30).toFixed(2) - 30}`),
    };
  }

  return output;
};

export type ITransitionElements = Map<string, NodeListOf<Element>>;

export const getTransitionElements = (transitions: ITransitionData): ITransitionElements => {
  const map = new Map<string, NodeListOf<Element>>();

  transitions.forEach((_, key) => {
    const selector = `#${key}`;
    const elements = document.querySelectorAll(selector);
    map.set(selector, elements);

  });
  return map;
};

export const browser = ((agent) => {
  switch (true) {
    case agent.includes('edge'):
      return "edge";
    case agent.includes("edg"):
      return "chromium-edge";
    case agent.includes('opr'):
      return "opera";
    case agent.includes('chrome'):
      return "chrome";
    case agent.includes("trident"):
      return "ie";
    case agent.includes("firefox"):
      return "firefox";
    case agent.includes("safari"):
      return "safari";
    default:
      return "other";
  }
})(window.navigator.userAgent.toLowerCase());
