import calculateStyles from "./calculateStyles";
import { ITransitionElements } from "./transition-utilities";
import { ITransitionData } from "./transitions";

const onTick = (transitionsData: ITransitionData, transitionElements: ITransitionElements) => {
  const currentFrame = window.pageYOffset;

  const styles = calculateStyles(currentFrame, transitionsData);
  styles.forEach(({ selector, style }) => {
    transitionElements.get(selector)?.forEach((element) => {
      element.setAttribute("style", style);
    });
  });
};

export default onTick;
