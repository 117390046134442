import React, { useEffect } from "react";
import { ReactComponent as Svg } from "./scene.svg";
import sceneTransitions from "./transitions";
import { getTransitionElements } from "./transition-utilities";
import onTick from "./tickFunction";

const getTicker = (observer: IntersectionObserver) => {
  document
    .querySelectorAll("[data-scene-placeholder]")
    .forEach((placeholder) => {
      observer.observe(placeholder);
    });
};

const createThreshold = (height: number) => {
  const count = window.Math.ceil(height / 1);
  const t = [];
  const ratio = 1 / count;
  for (let i = 0; i < count; i += 1) {
    t.push(i * ratio);
  }
  return t;
};

interface SceneProps {
  isPortrait: boolean;
  width: number;
  height: number;
}

export default function Scene({ isPortrait, width, height }: SceneProps) {
  useEffect(() => {
    window.scrollTo(0, 0);
    const transitionsData = sceneTransitions.transitions(isPortrait);
    const transitionElements = getTransitionElements(transitionsData);

    const observer = new IntersectionObserver(
      // onTick.bind(null, transitionsData, transitionElements),
      () => onTick(transitionsData, transitionElements),
      {
        threshold: createThreshold(
          sceneTransitions.duration + window.innerHeight
        ),
      }
    );

    getTicker(observer);

    return () => {
      observer.disconnect();
    };
  }, [isPortrait]);

  return <Svg width={width} height={height} />;
}
