import {
  animateSourceCodes,
  appearAt,
  disappearAt,
  display,
  drawStrokes,
  ISceneAnimationKeyframe,
  multiple,
  rotate3d,
  rotateAbout,
  scale,
  translate,
} from "./transition-utilities";


export type ITransitionData = Map<string, ISceneAnimationKeyframe>;

export interface ISceneTransitions {
  duration: number;
  transitions: (_: boolean) => ITransitionData
}

const duration = 9500;

const SceneTransitions: ISceneTransitions = {
  duration,
  transitions: (isPortrait) => {
    const cityScene = [400, 1800];
    const deskFadeIn = [1800, 3000];
    const developmentScene = [3000, 5000];
    const graphicsDesignScene = [5000, 6000];
    const tinkeringScene = [6000, 7000];
    const scene3 = [7000, 8500];
    const frameZoomed = isPortrait
      ? multiple(translate(-1800, -2000), scale(1.6))
      : scale(1);

    const scene2Transitions = {
      [deskFadeIn[1]]: {
        transform: multiple(translate(0, 0), scale(1)),
      },
      [deskFadeIn[1] + 600]: {
        transform: multiple(translate(1400, 300), scale(5.5)),
      },
      [scene3[0] - 200]: {
        opacity: 1,
        transform: multiple(translate(1400, 300), scale(5.5)),
      },
      [scene3[0]]: {
        opacity: 0,
        transform: multiple(translate(1400, 300), scale(5.5)),
      },
    };

    const dff = deskFadeIn[0] + 150;

    return new Map([
      //Pretext Scene
      ["pretext", disappearAt(0, 200)],

      // City Scene
      ["scene1 path", drawStrokes(cityScene[0], 400)],
      ["scene1 polygon", drawStrokes(cityScene[0], 400)],
      ["scene1", disappearAt(cityScene[1] - 400, 200)],
      ["hdbshadows1 path", appearAt(cityScene[0], 400, 0.5)],
      ["hdbshadows1 polygon", appearAt(cityScene[0], 400, 0.5)],
      ["hdbshadows2 path", appearAt(cityScene[0], 400, 0.5)],
      ["hdbshadows2 polygon", appearAt(cityScene[0], 400, 0.5)],
      ["hdbshadows3 path", appearAt(cityScene[0], 400, 0.5)],
      ["hdbshadows3 polygon", appearAt(cityScene[0], 400, 0.5)],
      ["treeshadows path", appearAt(cityScene[0], 400, 0.5)],
      ["treeshadows polygon", appearAt(cityScene[0], 400, 0.5)],

      // Room Scene
      ["scene2", appearAt(deskFadeIn[0] - 10)],
      [
        "floor",
        {
          [deskFadeIn[0]]: {
            transform: translate(0, -2000),
          },
          [deskFadeIn[0] + 100]: {
            transform: translate(0, 0),
          },
          ...disappearAt(deskFadeIn[1] - 400, 200),
        },
      ],
      [
        "leftwall",
        {
          [deskFadeIn[0] + 100]: {
            transform: translate(0, -2000),
          },
          [deskFadeIn[0] + 200]: {
            transform: translate(0, 0),
          },
          ...disappearAt(deskFadeIn[1] - 400, 200),
        },
      ],
      [
        "rightwall",
        {
          [deskFadeIn[0] + 200]: {
            transform: translate(0, -2000),
          },
          [deskFadeIn[0] + 300]: {
            transform: translate(0, 0),
          },
          ...disappearAt(deskFadeIn[1] - 400, 200),
        },
      ],
      ["desktable path", drawStrokes(dff, 500)],
      ["desktable polygon", drawStrokes(dff, 500)],
      ["roomitems path", drawStrokes(dff, 500)],
      ["roomitems polygon", drawStrokes(dff, 500)],
      ["rightmonitor path", drawStrokes(dff, 500)],
      ["rightmonitor polygon", drawStrokes(dff, 500)],
      ["leftmonitor path", drawStrokes(dff, 500)],
      ["leftmonitor polygon", drawStrokes(dff, 500)],
      ["deskitems path", drawStrokes(dff, 500)],
      ["deskitems polygon", drawStrokes(dff, 500)],
      ["deskshadows path", appearAt(dff, 300, 0.5)],
      ["deskshadows polygon", appearAt(dff, 300, 0.5)],
      ["roomitems", disappearAt(deskFadeIn[1] - 400, 200)],
      ["roomshadows", display(dff, 200, deskFadeIn[1] - 400, 200)],
      ["desktable", scene2Transitions],
      ["deskitems", scene2Transitions],
      ["rightmonitor", scene2Transitions],
      ["leftmonitor", scene2Transitions],
      ["deskshadows", scene2Transitions],

      //Development subscene
      ["displaysidebar path", drawStrokes(developmentScene[0], 300)],
      [
        "dev1",
        {
          ...appearAt(developmentScene[0], 100),
          ...disappearAt(developmentScene[0] + 500, 50),
        },
      ],
      [
        "dev2",
        {
          ...appearAt(developmentScene[0] + 600, 50),
          ...disappearAt(developmentScene[0] + 1100, 50),
        },
      ],
      [
        "dev3",
        {
          ...appearAt(developmentScene[0] + 1150, 50),
          ...disappearAt(developmentScene[1] - 100, 100),
        }
      ],
      ["lines", animateSourceCodes(developmentScene[0], developmentScene[1])],
      ["rdevdisplay path", drawStrokes(developmentScene[0], 100)],

      ["rdevdisplay", disappearAt(developmentScene[1], 200)],
      ["ldevdisplay", disappearAt(developmentScene[1], 200)],

      // Graphics subscene
      ["rgraphdisplay path", drawStrokes(graphicsDesignScene[0], 200)],
      [
        "rgraphdisplay",
        display(graphicsDesignScene[0], 200, graphicsDesignScene[1], 200),
      ],
      [
        "lgraphdisplay",
        display(graphicsDesignScene[0], 50, graphicsDesignScene[1], 200),
      ],
      ["node1 path", drawStrokes(graphicsDesignScene[0] + 250, 100)],
      ["node2 path", drawStrokes(graphicsDesignScene[0] + 500, 100)],
      ["node3 path", drawStrokes(graphicsDesignScene[0] + 750, 100)],
      [
        "blenderscene",
        {
          [graphicsDesignScene[0] + 50]: {
            transform: rotate3d(3, 2, -1, -50),
          },
          [graphicsDesignScene[0] + 250]: {
            transform: rotate3d(3, 2, -1, -20),
          },
          [graphicsDesignScene[0] + 500]: {
            transform: rotate3d(3, 2, -1, 0),
          },
          [graphicsDesignScene[0] + 750]: {
            transform: rotate3d(3, 2, -1, 20),
          },
          [graphicsDesignScene[1]]: {
            transform: rotate3d(3, 2, -1, 50),
          },
        },
      ],

      // Others SubScene
      ["rtradingdisplay path", drawStrokes(tinkeringScene[0], 200)],
      [
        "rtradingdisplay",
        display(tinkeringScene[0], 200, tinkeringScene[1], 200),
      ],
      ["ltradingdisplay path", drawStrokes(tinkeringScene[0], 200)],
      [
        "ltradingdisplay",
        display(tinkeringScene[0], 200, tinkeringScene[1], 200),
      ],

      // Scene 3
      [
        "scene3",
        {
          [scene3[0]]: {
            opacity: 0,
            transform: frameZoomed,
          },
          [scene3[0] + 200]: {
            opacity: 1,
            transform: frameZoomed,
          },
          [scene3[1] - 300]: {
            opacity: 1,
            transform: frameZoomed,
          },
          [scene3[1] - 100]: {
            opacity: 0,
            transform: frameZoomed,
          },
        },
      ],
      ["sgcircle", rotateAbout(scene3[0], scene3[1] - 500, 300)],
      ["p1", rotateAbout(scene3[0], scene3[1] - 500, 300)],
      ["p2", rotateAbout(scene3[0], scene3[1] - 500, -500)],
      ["p3", rotateAbout(scene3[0], scene3[1] - 500, 600)],
      ["p4", rotateAbout(scene3[0], scene3[1] - 500, -250)],
      ["p5", rotateAbout(scene3[0], scene3[1] - 500, 700)],

      ["plane", rotateAbout(scene3[0], scene3[1] - 500, -1100)],
      ["mrt", rotateAbout(scene3[0], scene3[1] - 500, -800)],
    ]);
  },
};

export default SceneTransitions;
