import { useEffect, useState } from "react";
import sceneTransitions1 from "./scene/transitions";
import Scene from "./scene";
import Subtitles from "./subtitles";
import { ReactComponent as LinkedInIcon } from "./resources/logo-linkedin.svg";
import { ReactComponent as EmailIcon } from "./resources/logo-email.svg";
import { ReactComponent as GithubIcon } from "./resources/logo-github.svg";
import { debounce } from "lodash";
import "./styles/main.scss";

const getWindowDimensions = () => ({
  height: window.innerHeight,
  width: window.innerWidth,
  isPortrait: window.innerHeight > window.innerWidth
});

export function App() {
  const DEBOUNCE_TIMER = 100;

  const [dimensions, setDimensions] = useState(getWindowDimensions);
  const screens = Math.round(sceneTransitions1.duration / dimensions.height);
  const arrayScreens = Array.from(Array(screens).keys());

  useEffect(() => {
    const debouncedHandleScreenResize = debounce(
      setDimensions.bind(null, getWindowDimensions),
      DEBOUNCE_TIMER
    );
    window.addEventListener("resize", debouncedHandleScreenResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleScreenResize);
    };
  }, []);


  return (
    <div className="App">
      {arrayScreens.map((el) => (
        <div
          key={el}
          data-scene-placeholder
          style={{ height: dimensions.height }}
        ></div>
      ))}

      <Scene
        width={dimensions.width}
        height={dimensions.height}
        isPortrait={dimensions.isPortrait}
      />

      <Subtitles isPortrait={dimensions.isPortrait} />

      <div className="contact-content">
        <a
          className="profile-btn"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/matthewkohjm/"
        >
          <LinkedInIcon />
        </a>
        <a
          className="profile-btn"
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:kjm.matthew@gmail.com"
        >
          <EmailIcon />
        </a>
        <a
          className="profile-btn"
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/kjunmin"
        >
          <GithubIcon />
        </a>
      </div>
    </div>
  );
}

export default App;